import { DocumentReference, DocumentSnapshot, FirestoreError, getDoc } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import useLoadingState from './useLoadingState';
import useMemoRef from './useMemoRef';

const useDocumentDataOnce = <T,>(ref: DocumentReference<T> | null) => {
  const { error, loading, reset, setError, setValue, value } = useLoadingState<DocumentSnapshot<T>, FirestoreError>();
  const memoRef = useMemoRef(ref, reset);
  useEffect(() => {
    getDoc(memoRef).then(setValue, setError);
  }, [memoRef, setError, setValue]);
  return useMemo(() => [value?.data(), loading, error] as const, [value, loading, error]);
};

export default useDocumentDataOnce;
